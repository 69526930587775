import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

export default function PageSizeCustomOptions({
  evaluationData,
  renderContent,
  loadingEvaluationData,
}) {
  // Define columns to match your table structure
  const columns = [
    { field: "srNo", headerName: "Sr.No", width: 80 },
    { field: "question", headerName: "Question", width: 300 },
    { field: "answer", headerName: "Answer", width: 300 },
    {
      field: "answer_correctness",
      headerName: "Answer Correctness",
      width: 180,
    },
    { field: "answer_relevancy", headerName: "Answer Relevancy", width: 180 },
    { field: "answer_similarity", headerName: "Answer Similarity", width: 180 },
    { field: "chunk_utilization", headerName: "Chunk Utilization", width: 200 },
    { field: "context", headerName: "Context", width: 200 },
    { field: "context_precision", headerName: "Context Precision", width: 180 },
    { field: "context_relevancy", headerName: "Context Relevancy", width: 180 },
    { field: "grade_score", headerName: "Grade Score", width: 150 },
    { field: "ground_truth", headerName: "Ground Truth", width: 180 },
    { field: "precision", headerName: "Precision", width: 180 },
    { field: "recall", headerName: "Recall", width: 180 },
    { field: "toxicity_reason", headerName: "Toxicity Reason", width: 200 },
  ];

  // Transform your existing data to fit DataGrid format
  const rows = evaluationData.map((data, index) => ({
    id: index, // Unique key for DataGrid
    srNo: index + 1,
    question: data.question,
    answer: data.answer,
    answer_correctness: data.answer_correctness,
    answer_relevancy: data.answer_relevancy,
    answer_similarity: data.answer_similarity,
    chunk_utilization: data.chunk_utilization
      .map((value) => parseFloat(value).toFixed(3))
      .join(", "),
    context: data.context,
    context_precision: data.context_precision,
    context_relevancy: data.context_relevancy,
    grade_score: data.grade_score,
    ground_truth: data.ground_truth,
    precision: data.precision,
    recall: data.recall,
    toxicity_reason: data.toxicity_reason,
  }));

  const [loading, setLoading] = useState(true); // Define loading state

  // Simulate data fetching
  useEffect(() => {
    // Mock API call or data preparation
    const timer = setTimeout(() => {
      setLoading(false); // Data is ready
    });

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="evaluation-table">
      <h2 className="text-center mb-4">Evaluation Table</h2>
    <Box >
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loadingEvaluationData}
        pageSize={5}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        pageSizeOptions={[5, 25, 20]}       
        // loading={true} // Add a loading state if needed
        disableSelectionOnClick
        sx={{
          backgroundColor: "white", // Light green
          color: "#155724", // Dark green text
          fontSize: "1rem",
        }}
      />
    </Box>
    </div>
  );
}
