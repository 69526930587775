import React, { useState, useRef, useEffect, useContext } from "react";
import "./index.css";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import { generateText, fetchData, uploadFile } from "../services/api.services";
import { ClipLoader } from "react-spinners";
import { FaArrowCircleDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import EvaluationTable from "./EvaluationTable"; // Adjust the path

// import { AuthContext } from "../services/AuthContext"; // Adjust the path as needed
import axios from "axios";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import {
  S3_BUCKET,
  REGION,
  ACCESS_KEY,
  SECRET_ACCESS_KEY,
} from "../AwsConfig/awsConfig";

import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";

const RightSide = ({ showFileInput, clearHistory, evaluationType }) => {
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const chatBoxRef = useRef(null);
  const navigate = useNavigate();
  // const { currentUser, initialLetter } = useContext(AuthContext);
  const [evaluationData, setEvaluationData] = useState([]);
  const [loadingEvaluationData, setLoadingEvaluationData] = useState(true);
  const [error, setError] = useState(null);
  const [expandedContent, setExpandedContent] = useState({});
  const [modalTitle, setModalTitle] = useState(""); // Add state for the column name
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [objectURL, setObjectURL] = useState("");

  // Define constants for your S3 bucket and region
  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
  const REGION = process.env.REACT_APP_AWS_REGION;
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

  const s3Client = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
    },
  });


  const fetchDataSize = async () => {
    setLoadingEvaluationData(true);
    try {
      const response = await axios.get(
        "https://llm.aiensured.com/api/evaluate?test_size=2"
      ); // Replace with your actual API endpoint
      const parsedData = JSON.parse(response.data); // Parse the JSON string into an object
      setEvaluationData(parsedData);
      console.log(evaluationData,"evaluatdata")
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message || "Unknown error");
    } finally {
      setLoadingEvaluationData(false);
    }
  };

  useEffect(() => {
    if (evaluationType === "Over All") {
      fetchDataSize();
    }
  }, [evaluationType]);


  // useEffect(() => {
  //   if (!currentUser) {
  //     navigate("/login"); // Redirect to login if not logged in
  //   }
  // }, [currentUser, navigate]);

  useEffect(() => {
    const savedChatHistory =
      JSON.parse(localStorage.getItem("chatHistory")) || [];
    setChatHistory(savedChatHistory);
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      const { scrollHeight, clientHeight } = chatBoxRef.current;
      setShowScrollButton(scrollHeight > clientHeight);
    }
  }, [outputText, loading]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatBoxRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = chatBoxRef.current;
        setShowScrollButton(scrollTop + clientHeight < scrollHeight - 10);
      }
    };

    const chatBox = chatBoxRef.current;
    if (chatBox) {
      chatBox.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatBox) {
        chatBox.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const uploadFile = async (e) => {
    const file = e.target.files[0]; // Get the file from the input event

    if (!file) {
      alert("Please select a file to upload.");
      return;
    }
    const uuid = uuidv4();
    // Create a new File object with the same content but renamed to 'dataset.pdf'
    const renamedFile = new File([file], "gst.pdf", { type: file.type });

    // Set state with the renamed file
    setFile(renamedFile);
    setFilename("gst.pdf"); // Set filename as 'dataset.pdf'
    const destPath = `${uuid}/gst.pdf`;

    const params = {
      Bucket: S3_BUCKET,
      Key: "gst.pdf", // Fixed file name for S3
      Body: renamedFile, // Use the renamed file
      ContentType: renamedFile.type,
    };

    try {
      const data = await s3Client.send(new PutObjectCommand(params));
      console.log("Upload Success", data);

      // Construct the S3 object URL with the renamed file name 'dataset.pdf'
      const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/gst.pdf`;

      setObjectURL(url);
      console.log("Object URL:", url);

      alert("File uploaded successfully!");

      // Send the S3 URL to the backend
      await axios.post(
        "https://llm.aiensured.com/api/download",
        {
          s3_url: url,
          filename: file.name,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("S3 URL sent to backend successfully!");
    } catch (err) {
      console.error("Error", err);
      alert("File upload failed.");
    }
  };

  const removeFile = () => {
    setFile(null);
    setFilename("");
  };

  // const handleSend = async () => {
  //   setLoading(true);
  //   setShowScrollButton(false);
  //   try {
  //     const response = await generateText(inputText);
  //     setOutputText(response.output);

  //     const newChat = {
  //       input: inputText,
  //       output: response.output,
  //       score: response.Evalution_scores,
  //     };
  //     const updatedChatHistory = [...chatHistory, newChat];
  //     setChatHistory(updatedChatHistory);
  //     localStorage.setItem("chatHistory", JSON.stringify(updatedChatHistory));
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  //   setLoading(false);
  // };

  const handleSend = async () => {
    if (!inputText.trim()) return; // prevent sending empty input

    // Immediately add the input to the chat with a placeholder output
    const newChat = {
      input: inputText,
      output: null, // placeholder while awaiting the response
      score: null, // placeholder while awaiting the response
    };
    const updatedChatHistory = [...chatHistory, newChat];
    setChatHistory(updatedChatHistory);

    setInputText(""); // Clear input field after submission
    setLoading(true); // Show loading spinner
    setShowScrollButton(false); // Hide scroll button during loading

    try {
      const response = await generateText(inputText);

      // Update the last chat message with output and evaluation score
      const lastChatIndex = updatedChatHistory.length - 1;
      updatedChatHistory[lastChatIndex].output = response.output;
      updatedChatHistory[lastChatIndex].score = response.Evalution_scores;
      console.log(response.Evalution_scores, "eval");
      setChatHistory([...updatedChatHistory]);
      localStorage.setItem("chatHistory", JSON.stringify(updatedChatHistory));
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Hide loading spinner
      setShowScrollButton(true); // Show scroll button after output is displayed
    }
  };

  // const scrollToBottom = () => {
  //   window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
  // };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      e.preventDefault();
      handleSend();
      setInputText("");
    }
  };

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);


  const toggleContent = (index, field) => {
    setExpandedContent((prevExpandedContent) => ({
      ...prevExpandedContent,
      [index]: {
        ...prevExpandedContent[index],
        [field]: !prevExpandedContent[index]?.[field],
      },
    }));
  };

  const handleOpenModal = (content, columnName) => {
    console.log(
      "Opening modal with content:",
      content,
      "and column name:",
      columnName
    ); // Debug log

    setModalContent(content);
    setModalTitle(columnName); // Set the column name
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalContent("");
    setModalTitle(""); // Clear the column name
  };

  const renderContent = (content, index, field, columnName) => {
    console.log("Rendering content with column name:", columnName); // Debug log
    const isExpanded = expandedContent[index]?.[field];
    if (content.length > 100) {
      return (
        <>
          {isExpanded ? content : `${content.substring(0, 150)}...`}
          <button
            className="readMore"
            onClick={() =>
              isExpanded
                ? toggleContent(index, field)
                : handleOpenModal(content, columnName)
            } // Ensure columnName is passed
          >
            {isExpanded ? "Read Less" : "Read More"}
          </button>
        </>
      );
    }
    return content;
  };

  return (
    <main className="content">
      <div className="user-info">{/* <User /> */}</div>

      <div className="rightSide">
        {evaluationType === "Over All" ? (
            <div>
              <EvaluationTable
                evaluationData={evaluationData}
                loadingEvaluationData={loadingEvaluationData}
                renderContent={renderContent}
              />
          </div>
        ) : (
          <div className="chat-box-wrapper" ref={chatBoxRef}>
            <div className="output">
              {chatHistory.map((chat, index) => (
                <div key={index} className="chat-message">
                  <p className="lastinput">{chat.input}</p>
                  <div className="response-container">
                    <p className="response">{chat.output}</p>
                    {chat.score && (
                      <div className="scoreTable">
                        <TableContainer
                          component={Paper}
                          style={{
                            borderRadius: "1.2rem",
                            backgroundColor: "#3b3939",
                          }}
                        >
                          <Table
                            sx={{ minWidth: 300 }}
                            aria-label="evaluation scores table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{ color: "white", fontWeight: "bold" }}
                                >
                                  Metric
                                </TableCell>
                                <TableCell
                                  style={{ color: "white", fontWeight: "bold" }}
                                  align="center"
                                >
                                  Value
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.entries(chat.score).map(
                                ([key, value]) => (
                                  <TableRow
                                    key={key}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell style={{ color: "white" }}>
                                      {key.replace("_", " ")}
                                    </TableCell>
                                    <TableCell
                                      style={{ color: "white" }}
                                      align="center"
                                    >
                                      {Array.isArray(value)
                                        ? value
                                            .map((v) =>
                                              parseFloat(v.toFixed(3))
                                            )
                                            .join(", ")
                                        : typeof value === "number"
                                        ? parseFloat(value.toFixed(3))
                                        : value || "N/A"}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              {/* Loading Spinner */}
              {loading && (
                <div className="loading-container">
                  <ClipLoader
                    color={"#a03c64"}
                    loading={loading}
                    size={100}
                    cssOverride={{
                      borderWidth: "10px",
                    }}
                  />
                </div>
              )}
              {showScrollButton && (
                <button onClick={scrollToBottom} className="scroll-to-bottom">
                  <FaArrowCircleDown className="scrolllogo" />
                </button>
              )}
            </div>
          </div>
        )}

        {evaluationType !== "Over All" && (
          <div className="inputmodel">
            <div className="fixed-input-area">
              {/* {showFileInput && (
                <>
                  <AttachFileIcon
                    
                    className="attach-icon"
                    onClick={() => document.getElementById("fileInput").click()}
                    style={{ cursor: "pointer" }}
                  />
                  <input
                    id="fileInput"
                    type="file"
                    accept=".pdf"
                    hidden
                    onChange={uploadFile} // Combined handler for selection and upload
                  />
                </>
              )} */}
              <input
                type="text"
                className="chat-input"
                placeholder="Type here to chat."
                value={inputText}
                onChange={(e) => {
                  if (e.target.value.length <= 1024) {
                    setInputText(e.target.value);
                  }
                }}
                maxLength={1024}
              />
              <button
                type="button"
                onKeyPress={handleKeyPress}
                className="send-button"
                onClick={handleSend}
              >
                &#x27A4;
              </button>
            </div>
          </div>
        )}
      </div>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "40%",
            left: "55%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "200vh",
            overflowY: "auto",
            backgroundColor: "#e6e3e3",
            color: "black",
            borderRadius: "1rem",
            textAlign: "justify",
            textJustify: "inter-word",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              fontWeight: "bold",
              fontSize: "4.5rem", // Increase font size for a larger icon
              color: "black", // Set color to black for a darker appearance
              "&:hover": {
                color: "gray", // Optional: change color on hover
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h6"
            component="h1"
            sx={{ fontWeight: "bold", ml: 1 }}
          >
            {modalTitle}
          </Typography>
          <Typography
            sx={{ mt: 2, backgroundColor: "white", borderRadius: "1rem", p: 4 }}
          >
            {modalContent}
          </Typography>
        </Box>
      </Modal>
    </main>
  );
};

export default RightSide;
	