

import React, { useState } from "react";
import Logo from "../Images/logo.png";

const Menu = ({ showFileAtRAg, clearHistory, setEvaluationType }) => {
  const [chatType, setChatType] = useState("");

  const handleChatTypeChange = (e) => {
    const selectedChatType = e.target.value;
    setChatType(selectedChatType);
    showFileAtRAg(selectedChatType === "Rag Evaluation");
  };

  const handleEvaluationTypeChange = (e) => {
    const selectedEvaluationType = e.target.value;
    setEvaluationType(selectedEvaluationType); // Update evaluation type state
  };

  return (
    <>
      <aside className="sidebar">
        <div className="app-logo">
          <img className="clogo" src={Logo} alt="logo" />
        </div>
        <div className="right">
          <div className="form-group">
            <select
              className="selectmodel"
              onChange={handleChatTypeChange}
              value={chatType}
            >
              <option value="">Choose Chat Type</option>
              <option value="Rag Evaluation">Rag Evaluation</option>
            </select>
          </div>
          
          <div className="form-group">
            <select  onChange={handleEvaluationTypeChange} className="selectmodel">
              {/* <option value="">Evaluation Type</option> */}
              <option value="single">Single Query Evaluation</option>
              <option value="Over All">Over All Evaluation</option>
            </select>
          </div>
          <div >
            <button className="addicon" onClick={clearHistory}>Clear chat</button>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Menu;


